/*
 * Breakpoints
 */
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;

/*
  Colors
*/
$white-color: #fff;
$black-color: #000;
$main-text-color: #1f2c47;
$main-text-color-10: #0f1624;
$main-gray-color: #8592ad;
$main-primary-color-95: #e9effb;
$main-primary-color-55: #3c71dd;
$main-primary-color-50: #2662d9;
$main-primary-color-45: #2258c3;
$main-light-color-97: #f7f7f8;
$main-light-color-95: #f1f2f4;
$main-light-color-90: #e3e5e8;
$main-light-color-80: #c7cad1;

/*
  Sizes
*/

$navbar-small-size: 48px;
$navbar-big-size: 200px;
$page-size: 1024px;
$page-size-tablet: 672px;
$page-size-mobile: 320px;
$breakpoint-tablet: 1023px;
$breakpoint-mobile: 767px;
/*
  Animations
*/

$animation-duration: 0.3s;

/*
  Fonts
*/

@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Work_Sans/WorkSans-Regular.ttf") format("woff");
}

@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/Work_Sans/WorkSans-Medium.ttf") format("woff");
}

@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/Work_Sans/WorkSans-SemiBold.ttf") format("woff");
}

@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Work_Sans/WorkSans-Bold.ttf") format("woff");
}
