@import "node_modules/bulma/sass/utilities/_all.sass";
@import "node_modules/bulma/sass/base/_all.sass";
@import "node_modules/react-toastify/scss/main.scss";
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import "./variables.scss";

body {
  margin: 0;
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  outline: none !important;
}

h1 {
  font-family: "Work Sans";
  font-weight: bold;
  font-size: 40px;
  line-height: 130%;
}

h2 {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 160%;
}

h3 {
  font-family: "Work Sans";
}

h4 {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 155%;
  color: #1f2c47;
}

h5 {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 155%;
  color: #1f2c47;
}

h6 {
  font-family: "Work Sans";
}

p {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.04em;
}

span {
  font-family: "Work Sans";
}

/*
// Hide Scrollbar?
::-webkit-scrollbar {
  display: none;
}
*/
.borderBox {
  border-bottom: 1px solid $main-light-color-90;
}

.no-item-yet {
  color: $main-gray-color;
  margin: auto;
  text-align: center;
}

.login-required-wrapper {
  min-height: 70vh;
  padding-top: 30vh;

  h1 {
    margin: 30px 0;
    text-align: center;
  }
}

.go-back-button {
  background: #2662d9 !important;
  color: white !important;
}

.error-label {
  color: red;
  font-size: 0.9rem;
  font-weight: 600;
}

.mt-10 {
  margin-top: 10px;
}

.mb-30 {
  margin-bottom: 30px;
}

.border-gradient {
  position: relative;
  background: #f1f2f4;
  /*The background extends to the outside edge of the padding. No background is drawn beneath the border.*/
  background-clip: padding-box;
  border-radius: 10px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -8px; /* same as border width */
    border-radius: inherit; /* inherit container box's radius */
  }
}

.border-gradient-1:before {
  background: linear-gradient(to left, #f9f047, #0fd850);
}

.border-gradient-2:before {
  background: linear-gradient(to left, #fF9cac, #2b86c5);
}

.border-gradient-3:before {
  background: linear-gradient(to left, #ff3cac, #562b7c);
}

.border-gradient-4:before {
  background: linear-gradient(to left, #f83600, #f9d423);
}

.loader-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

